import React from "react";
import styles from "./Footer.module.css";
import { FooterSection } from "./FooterSection";
import { ContactInfo } from "./ContactInfo";

export const Footer: React.FC = () => {
  const companyLinks = [
    { text: "Home", href: "/" },
    { text: "Features", href: "/features" },
    { text: "How it works", href: "/how-it-works" },
    { text: "Download The App", href: "/download" },
  ];

  const infoLinks = [
    { text: "Home", href: "/" },
    { text: "Features", href: "/features" },
  ];

  const contactInfo = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/93175c3612accfa4c56bc4c0817fe6c5970ba715eee70a60aa4625bc5c06f6f2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      text: "+91 9742468701",
      href: "tel:+919742468701",
      alt: "Phone icon",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/3d7671f2f0f78b78b56f86b1202f10a6ca91de97039b081dee75f03803d6f09b?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      text: "info@exprzn.com",
      href: "mailto:info@trendutube.com",
      alt: "Email icon",
    },
  ];

  return (
    <footer className={styles.footer} role="contentinfo">
      <div className={styles.footerContent}>
        <div className={styles.brandSection}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/161c67de8d84e14203df7244227cb92179e2031549dbadf5107a95a52c82728b?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
            alt="Trendutube logo"
            className={styles.logo}
          />
          <span className={styles.brandName}>Trendutube</span>
        </div>

        <div className={styles.sectionsContainer}>
          <FooterSection title="COMPANY" links={companyLinks} />
          <FooterSection title="INFO" links={infoLinks} />

          <div className={styles.contactSection}>
            <h2 className={styles.sectionTitle}>Contact Us</h2>
            <div className={styles.contactContainer}>
              {contactInfo.map((info, index) => (
                <ContactInfo key={index} {...info} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divider}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/5cdbd9b5d338549d27a2395de05097e704f71b6914f3c48312e84fc4f8428b3b?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
          alt=""
          className={styles.dividerImage}
        />
      </div>

      <div className={styles.copyright}>
        ©2023 | Expressare Technologies Private Limited | All rights reserved
      </div>
    </footer>
  );
};
