import React from "react";
import styles from "./Features.module.css";

interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
  className?: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  className,
}) => {
  return (
    <article className={`${styles.featureCard} ${className || ""}`}>
      <img loading="lazy" src={icon} alt="" className={styles.featureIcon} />
      <div className={styles.featureContent}>
        <h3 className={styles.featureTitle}>{title}</h3>
        <p className={styles.featureDescription}>{description}</p>
      </div>
    </article>
  );
};
