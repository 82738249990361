import React from "react";
import { NavItemProps } from "../types";
import styles from "./NavigationBar.module.css";

const navItems: NavItemProps[] = [
  { text: "Home" },
  { text: "Features" },
  { text: "How It Works" },
];

const downloadButton = {
  text: "Download App",
  icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/135a8e967bf14f688c1f12d681594c6eebddf25b0d5051abf4982cf93fad1673?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
  alt: "Download button background",
}

export const NavigationBar: React.FC = () => {
  return (
    <nav className={styles.navigationBar} role="navigation">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/533e5077c9ae7496a111ac5f3f896078e7ebf29d058823e9f7bdc2dc4e912d76?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
        className={styles.logo}
        alt="Company Logo"
      />
      <div className={styles.navContainer}>
        <ul className={styles.navMenu}>
          {navItems.map((item, index) => (
            <li key={index}>
              <a
                href={`#${item.text.toLowerCase()}`}
                className={styles.navLink}
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
        {/* <button className={styles.downloadButton}>Download App</button> */}
        <button
        className={styles.downloadButton}
        type="button"
        aria-label="Download application"
      >
        {/* <div className={styles.buttonContent}>
          <img
            loading="lazy"
            src={downloadButton.icon}
            alt={downloadButton.alt}
            className={styles.buttonIcon}
          />
          <span>{downloadButton.text}</span>
        </div> */}
      </button>
      </div>
    </nav>
  );
};
