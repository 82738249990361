import React from "react";
import { FeatureCard } from "./FeatureCard";
import styles from "./Features.module.css";

const features = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/c960cefa9c9662530b82efc564d56dd211add17bd2d0a756c7f938542be2bad2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Luxurious Jewellery",
    description: "Exotic Jewellery from top designers",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/278023df9c901672ecc78971cdf25a62601e9944c155be0f776ac478d2c8ebfb?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Fashion",
    description: "Stay ahead with the latest in fashion trends.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/4f2e93ac044dfc8792efe8b63fb58b19fb74fce311a8fb7ea6511d9705991188?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Artfully designed Wristwear",
    description: "Smart timepieces and modern wrist accessories.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/f87d258aa8441c33e879f2b725f72af0df1cd41a863e8815aabcef8c07a9428f?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Many More",
    description: "The ultimate collection of wearables for all.",
  },
];

export const Features: React.FC = () => {
  return (
    <section className={styles.featuresSection}>
      <div className={styles.container}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/fd6f410cf53582cee04c656350fa8df5e44ef4b3ce574f9d9f4ff2f28370ffb2?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
          alt=""
          className={styles.backgroundImage}
        />
        <div className={styles.content}>
          <h2 className={styles.heading}>
            One Stop Entertainment and Shopping
          </h2>
          <div className={styles.description}>
            <p className={styles.subheading}>
              Bringing you an unique combination of social interaction,
              entertainment and shopping
            </p>
            <div className={styles.featuresList}>
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  {...feature}
                  className={styles[`feature${index + 1}`]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
