import React from "react";
import styles from "./ShopperBenefits.module.css";
import { FeatureCardProps } from "./types";
import { IconImage } from "./IconImage";

export const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  imagePosition = "left",
  image,
}) => {
  const content = (
    <div className={styles.featureContent}>
      <IconImage src={icon} alt="" />
      <h3 className={styles.featureTitle}>{title}</h3>
      <p className={styles.featureDescription}>{description}</p>
    </div>
  );

  return (
    <div className={styles.featureContainer}>
      {imagePosition === "left" && image && (
        <div className={styles.imageWrapper}>
          <img
            src={image}
            alt=""
            className={styles.featureImage}
            loading="lazy"
          />
        </div>
      )}
      {content}
      {imagePosition === "right" && image && (
        <div className={styles.imageWrapper}>
          <img
            src={image}
            alt=""
            className={styles.featureImage}
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
};
