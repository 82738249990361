import React from "react";
import styles from "./Footer.module.css";
import { FooterLink } from "./FooterLink";
import { FooterSectionProps } from "./types";

export const FooterSection: React.FC<FooterSectionProps> = ({
  title,
  links,
}) => (
  <div className={styles.footerSection}>
    <h2 className={styles.sectionTitle}>{title}</h2>
    <nav className={styles.linkContainer}>
      {links.map((link, index) => (
        <FooterLink key={index} {...link} />
      ))}
    </nav>
  </div>
);
