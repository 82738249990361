
export const preferences = {
  backgroundImage:
    "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/e3f817a909a74ac7ec558db010a9e5b38e95d53647d192e1bbb6e12b642f39c6?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
  title: "Preferences\nSet you preferences,\nstore your favorites with ease",
  subtitle:
    "The slickest app in the market catering to fashion and entertainment",
  storeButtons: [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/d03b915c10f84be316b52cdf0b0a7279407062ec4c2879bc931f7bea7e85e580?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      alt: "App Store Download",
      width: 154,
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/9b91895c5450c4c05b7d0081bc010692c274a34bcb9bb24812aa4dff601bedea?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      alt: "Google Play Download",
      width: 155,
    },
  ],
};
  