import React from "react";
import styles from "./ShopperBenefits.module.css";
import { FeatureCard } from "./FeatureCard";

const features = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/3aadceb887ae00552b6979ca2bdde8c2457c3b3632b03520e3c973e0b23254b3?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Videos, Entertainment, Shopping",
    description:
      "Videos bring products to life with 360-degree views, detailed demonstrations, and try-before-you-buy features, giving shoppers confidence in their purchases.",
    image:
      "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/7910ec4c59e1c96aa7fea7bb3ccb90fc990823d12dc3db9cea073656bcbf733d?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    imagePosition: "left" as const,
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/d001af8203603f1bfc798e58e29a21178d54f2bc6371709ae836b4dac219d850?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Choose Variants",
    description:
      "Supports easy handling of several variants. Choose precise Size, color and patterns",
    image:
      "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/de02d3e589dd2821130b36af869a541d63616de3b03cab6f6ce8e0ece333f4e2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    imagePosition: "right" as const,
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/1f436b1dfb35222f45426e7aa5823973d7098557a0e806a256f6dde7562c2cd2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    title: "Cart",
    description: "Get Discounts, Referral discounts and Community discounts",
    image:
      "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/aa9320b43e6168e68d396e7f86b1eafc2f0ac1d7995d65f1fbc9fba4737d3be8?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    imagePosition: "left" as const,
  },
];

export const ShopperBenefits: React.FC = () => {
  return (
    <section className={styles.benefitsSection}>
      <div className={styles.container}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/b0e6eec235cdf980b2ac9c2b57867f9f865019e31bbccc1cc422b3afe8c920a1?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
          alt=""
          className={styles.backgroundImage}
        />
        <div className={styles.content}>
          <h1 className={styles.mainTitle}>What is in it for Shoppers ?</h1>
          <h2 className={styles.subtitle}>
            Shop while watching videos. Easy to use.
          </h2>
          <div className={styles.featuresGrid}>
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                image={feature.image}
                imagePosition={feature.imagePosition}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
