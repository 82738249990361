import React from "react";
import { HeroImageProps } from "../types";
import styles from "./HeroSection.module.css";

const heroImages: HeroImageProps[] = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/da29d0dc795ae03e1ebaf4f96bc772c68977ab16a3177a6dfe37db55a87b52c5?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    alt: "Hero Image 1",
    className: styles.heroImage,
    width: 269,
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/4e5395c1967358d786a6aad92e03e7b2220689c116efedfff7e22e2bc85b9582?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    alt: "Hero Image 2",
    className: styles.heroImage,
    width: 268,
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/a41037e1661eb46b516ba63a9d732a4fe647631815a14e7322ceca7f664da323?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
    alt: "Hero Image 3",
    className: styles.heroImage,
    width: 268,
  },
];

export const HeroSection: React.FC = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.backgroundWrapper}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/5eafce17c0c4fd5f1c444342623c683bafb0061eb706c8691aa5ec06245cc17c?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
          className={styles.backgroundImage}
          alt="Background Pattern"
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.heroContent}>
          <div className={styles.badge}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/28aa6e66582f50a9e0cbfe153b783eed99ef44c49c6773415cc1229e37959165?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
              className={styles.badgeIcon}
              alt="Entertainment Icon"
            />
            <span className={styles.badgeText}>#1 entertainment</span>
          </div>
          <div className={styles.textContent}>
            <h1 className={styles.mainHeading}>
              View, Swipe, Checkout
              <br />
              It is that simple.
            </h1>
            <p className={styles.subHeading}>
              While you enjoy entertainment
              <br />
              do your shopping
            </p>
          </div>
          <div className={styles.storeButtons}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/0cfab0433bd01968f74c0476f32f31b4fda5d95d119da759db4ba761ee6bb9e1?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
              className={styles.storeButton}
              alt="Download on App Store"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/8d2ec83771ce23471b209b421d012287f671634c2cdf9391e365bd97af3cba3c?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
              className={styles.storeButton}
              alt="Get it on Google Play"
            />
          </div>
        </div>
        <div className={styles.heroImages}>
          {heroImages.map((image, index) => (
            <img
              key={index}
              loading="lazy"
              src={image.src}
              className={image.className}
              alt={image.alt}
              width={image.width}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
