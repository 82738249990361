import React from "react";
import styles from "./SellerSection.module.css";
import { Step } from "./Step";
import { SellerStepProps } from "./types";

const steps = [
  {
    isActive: true,
    title: "Join Our Seller Community",
    description:
      "Be part of a growing network of successful creator-sellers who trust Trendutube to help them reach new heights. Our dedicated seller support team is here to assist you at every step.",
  },
  {
    title: "Assistance in Content Creation",
    description:
      "Our dedicated team of videographers and editors can assist you in creating quality content",
  },
  {
    title: "Logistics Handled",
    description:
      "All your logistics woes end here We take care of end to end delivery of all your products and there tracking.",
  },
];

export const SellerSection: React.FC = () => {
  return (
    <section className={styles.sellerSection}>
      <div className={styles.container}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/98b4eecfb80fc4c643250932cf888742e286eb076a017fa5dc952c5d86542f8b?apiKey=8bcdb730f36d48f4b01c68155e678c39&"
          className={styles.backgroundImage}
          alt=""
          role="presentation"
        />
        <div className={styles.content}>
          <h1 className={styles.heading}>
            Are you a content creator and want to sell?
          </h1>
          <div className={styles.description}>
            <p className={styles.subheading}>
              If you have been a great content creator or
              <br />a seller already who stirrs the social media,
              <br />
              this is your opportunity to earn,
            </p>
            <div className={styles.stepsContainer}>
              {steps.map((step, index) => (
                <Step
                  key={index}
                  isActive={step.isActive}
                  title={step.title}
                  description={step.description}
                />
              ))}
            </div>
            <button
              className={styles.actionButton}
              onClick={() => (window.location.href = "/download")}
              aria-label="Download Application"
            >
              Download App Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
