import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LandingPage } from './components/landingPage/LandingPage';
import { Features } from './components/features/Features';
import { SellerSection } from './components/sellerSection/SellerSection';
import { Shoppers } from './components/shoppers/Shoppers';
 import { Footer } from './components/footer/Footer';
import { PreferencesSection } from './components/preferences/PreferencesSection';
import { preferences } from './components/preferences/preferences';
import Navigation from './components/navigation/Navigation';
import { navigationData } from './components/navigation/navigation-data';
import { VariantFeature } from './components/ex/features/variants/VariantFeature';
import { Showcase } from './components/features/showcase/Showcase';
import { ShopperFeatures } from './components/shopperFeatures/ShopperFeatures';
import { ShopperBenefits } from './components/ex/shopperBenefits/ShopperBenefits';
const props = {
  heading: "Test Heading",
  subheading: "Test Subheading",
  backgroundImage: "test-bg.jpg",
  items: [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/3aadceb887ae00552b6979ca2bdde8c2457c3b3632b03520e3c973e0b23254b3?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      title: "Videos, Entertainment, Shopping",
      description:
        "Videos bring products to life with 360-degree views, detailed demonstrations, and try-before-you-buy features, giving shoppers confidence in their purchases.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/7910ec4c59e1c96aa7fea7bb3ccb90fc990823d12dc3db9cea073656bcbf733d?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      imageAlt: "Video shopping demonstration",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/d001af8203603f1bfc798e58e29a21178d54f2bc6371709ae836b4dac219d850?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      title: "Choose Variants",
      description: "Supports easy handling of several variants.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/de02d3e589dd2821130b36af869a541d63616de3b03cab6f6ce8e0ece333f4e2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      imageAlt: "Product variants interface",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/1f436b1dfb35222f45426e7aa5823973d7098557a0e806a256f6dde7562c2cd2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      title: "Cart",
      description: "Easy applying of discounts.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/aa9320b43e6168e68d396e7f86b1eafc2f0ac1d7995d65f1fbc9fba4737d3be8?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
      imageAlt: "Shopping cart interface",
    },
  ]
};
const mockProps = {
  iconSrc:
    "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/ffffce88e1883d0e28659173693fc0b1e170f5526f9331d5c0da53f789568201?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
  title: "Choose Variants",
  description: "Supports easy handling of several variants.",
  previewImageSrc:
    "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/2a1caeec9cba6986fef3c448340148e5134e683ef96b9d30b9da82cc9d3e0a8a?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
  phoneImageSrc:
    "https://cdn.builder.io/api/v1/image/assets/8bcdb730f36d48f4b01c68155e678c39/de02d3e589dd2821130b36af869a541d63616de3b03cab6f6ce8e0ece333f4e2?apiKey=8bcdb730f36d48f4b01c68155e678c39&",
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Navigation {...navigationData} /> */}
    <LandingPage />
    <Features />
    <SellerSection />
    {/* <Shoppers /> */}
    <ShopperBenefits />
    <PreferencesSection {...preferences}/>
    {/* <VariantFeature {...mockProps} /> */}
    {/* <Showcase {...props}/> */}
    <Footer />
    {/* <ShopperFeatures /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
