import React from "react";
import { StoreButtonProps } from "./types";
import styles from "./Preferences.module.css";

export const StoreButton: React.FC<StoreButtonProps> = ({
  src,
  alt,
  width,
  className,
}) => (
  <img
    loading="lazy"
    src={src}
    alt={alt}
    className={className}
    style={{ width }}
  />
);
