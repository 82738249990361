import React from "react";
import styles from "./SellerSection.module.css";
import { StepProps } from "./types";

export const Step: React.FC<StepProps> = ({ isActive, title, description }) => {
  return (
    <div className={styles.stepContainer}>
      <div className={styles.stepIndicator}>
        <div
          className={
            isActive ? styles.activeIndicator : styles.inactiveIndicator
          }
        />
        {isActive && <div className={styles.activeCenter} />}
      </div>
      <div className={styles.stepContent}>
        <h3 className={styles.stepTitle}>{title}</h3>
        <p className={styles.stepDescription}>{description}</p>
      </div>
    </div>
  );
};
