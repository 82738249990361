import React from "react";
import { NavigationBar } from "./components/NavigationBar";
import { HeroSection } from "./components/HeroSection";
import styles from "./LandingPage.module.css";

export const LandingPage: React.FC = () => {
  return (
    <main className={styles.landingPage}>
      <NavigationBar />
      <HeroSection />
    </main>
  );
};
