import React from "react";
import styles from "./Footer.module.css";
import { ContactInfoProps } from "./types";

export const ContactInfo: React.FC<ContactInfoProps> = ({
  icon,
  text,
  href,
  alt,
}) => (
  <a href={href} className={styles.contactItem}>
    <img src={icon} alt={alt} className={styles.contactIcon} />
    <span className={styles.contactText}>{text}</span>
  </a>
);
