import React from "react";
import { StoreButton } from "./StoreButton";
import { PreferencesSectionProps } from "./types";
import styles from "./Preferences.module.css";

export const PreferencesSection: React.FC<PreferencesSectionProps> = ({
  backgroundImage,
  title,
  subtitle,
  storeButtons,
}) => {
  return (
    <section className={styles.preferencesSection}>
      <div className={styles.contentWrapper}>
        <img
          loading="lazy"
          src={backgroundImage}
          alt=""
          className={styles.backgroundImage}
        />
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <div className={styles.mainContent}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.subtitle}>{subtitle}</p>
              <div className={styles.storeButtonsContainer}>
                {storeButtons.map((button, index) => (
                  <StoreButton
                    key={index}
                    {...button}
                    className={styles.storeButton}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
